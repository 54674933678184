// userflow
import userflow from 'userflow.js'

// types
import type { Enrollment, Nullable, Student } from '@revolutionprep/types'

// error handler
import { useErrorHandler } from '#imports'

export default defineNuxtPlugin({
  name: 'userflow',
  parallel: true,
  setup () {
    /**
     * nuxt app & runtime config
     * ==================================================================
     */
    const { $actor } = useNuxtApp()
    const config = useRuntimeConfig()
    const userflowToken = config.public.userflowToken

    const { doHandleError } = useErrorHandler()

    /**
     * stores
     * ==================================================================
     */
    const enrollmentStore = useEnrollmentStore()
    const { enrollments } = storeToRefs(enrollmentStore)

    /**
     * init userflow
     * ==================================================================
     */
    if (userflowToken) {
      userflow.init(userflowToken)
    }

    /**
     * methods
     * ==================================================================
     */
    const getMembershipRole = (enrollment: Enrollment) => {
      const { course, tutors } = enrollment
      if (course!.provisionedDuration <= 0) {
        // provisioned duration <= 0
        return 'completed'
      }
      if (tutors!.length) {
        if (course!.unscheduledDuration) {
          // tutor assigned and unscheduled duration && provisioned duration > 0
          return 'unscheduled'
        } else {
          // tutor assigned and provisioned duration > 0
          return 'active'
        }
      } else {
        // no tutor assigned and remaining provisioned duration > 0
        return 'pending'
      }
    }

    const getPrivateTutoringEnrollment = (
      enrollments: Enrollment[]
    ): Enrollment | undefined => {
      return enrollments.find(
        (enrollment: Enrollment) =>
          enrollment.title === 'Private Tutoring'
      )
    }

    const hasPrivateTutoringEnrollment = (
      enrollments: Enrollment[]
    ): boolean => {
      return Boolean(getPrivateTutoringEnrollment(enrollments))
    }

    /**
     * watchers
     * ==================================================================
     */
    $actor.core.storage.watchState('actor', async (
      actor: Nullable<Student>
    ) => {
      if (
        actor &&
        !$actor.core.spoofing.value &&
        !userflow.isIdentified()
      ) {
        // attempt to identify actor with userflow
        const userId = actor.userId
        try {
          if (!userId) {
            throw new Error('Userflow - No user ID, cannot identify')
          }
          await userflow.identify(
            userId.toString(),
            {
              actor_id: userId,
              actor_type: actor.actorType,
              name: actor.firstName,
              email: actor.email,
              grade: actor.grade,
              graduation_year: actor.graduationYear,
              sign_in_count: actor.signInCount,
              signed_up_at: actor.createdAt
            },
            {
              signature: actor.userflowToken
            }
          )
        } catch (error) {
          doHandleError(error as Error, false)
        }
      }
    },
    {
      immediate: true
    })

    watch(enrollments, async (newEnrollments) => {
      // if private tutoring enrollment changes and we are not spoofing
      // set Userflow group again
      if (
        !$actor.core.spoofing.value &&
        newEnrollments.length &&
        hasPrivateTutoringEnrollment(newEnrollments) &&
        userflow.isIdentified()
      ) {
        const enrollment = getPrivateTutoringEnrollment(newEnrollments)
        if (enrollment?.brand?.userflowToken) {
          // attempt to set Userflow group
          try {
            await userflow.group(
              enrollment.brand.id.toString(),
              {
                name: enrollment.brand.name
              },
              {
                membership: {
                  role: getMembershipRole(enrollment)
                },
                signature: enrollment.brand.userflowToken
              }
            )
          } catch (error) {
            doHandleError(error as Error)
          }
        }
      }
    })
  }
})
